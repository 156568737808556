import axios, { AxiosInstance } from "axios";
import { config } from "@/utils/config";
import { AxiosErrorWithResponse, intercept } from "@/api/AxiosInterceptors";
import { from, Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { ResellerErrHandlers } from "./ErrHandlers";
import { CommonErrHandlers, handleErrs } from "../ErrHandlers";
import { Reseller, ResellerContact, ResellerUserConfig, DisabledPricingRQ, DeactiveTicketsRQ, ListDeactiveTickets } from "./ResellerModels";
import { PosErrHandlers } from "../pos/ErrHandlers";
import { AuditRecordRQ, FeatureFlags } from "@/models/ModelsOppInventory";

export class ResellerClient {
  private httpClient: AxiosInstance;
  private xsrfCookieName = "XSRF-TOKEN";

  constructor() {
    this.httpClient = axios.create({
      baseURL: config.ResellersApiUrl,
      timeout: parseInt(config.ApiTimeout),
      withCredentials: true,
      xsrfCookieName: this.xsrfCookieName,
      xsrfHeaderName: "X-CSRF-Token",
      headers: {
        "Content-Type": "application/json",
      },
    });

    this.httpClient.interceptors.request.use(intercept.Request);
    this.httpClient.interceptors.response.use(intercept.Response, intercept.Error(ResellerClient.ErrorResponse));
  }

  private static ErrorResponse(err: AxiosErrorWithResponse) {
    // console.log("ErrorResponse", err)
    if (err.response?.status === 401) {
      return ResellerClient.on401(err);
    }

    if (err.response.status >= 400 && err.response.status < 500) {
      const wpsErr = err.response.data;
      return Promise.reject({
        status: err.response.status,
        wpsErr,
        ...err,
      });
    }

    // not 4xx
    return Promise.reject({
      status: err.response.status,
      ...err,
    });
  }

  private static on401(err: any): Promise<any> {
    return Promise.reject(err);
  }

  public handle401(on401: (err: any) => void) {
    ResellerClient.on401 = (err: any) => {
      on401(err);
      return Promise.reject(err);
    };
  }

  public getResellers(errs?: ResellerErrHandlers): Observable<Reseller[]> {
    return from(this.httpClient.get<Reseller[]>("")).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }

  public getContacts(resellerId: string, errs?: ResellerErrHandlers): Observable<ResellerContact[]> {
    return from(this.httpClient.get<ResellerContact[]>(`/${resellerId}/contacts`)).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }

  public deleteContact(resellerId: string, contactID: string, errs?: ResellerErrHandlers): Observable<any> {
    return from(this.httpClient.delete<any>(`/${resellerId}/contacts/${contactID}`)).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }

  public storeContact(contact: ResellerContact, errs?: ResellerErrHandlers): Observable<ResellerContact> {
    return from(this.httpClient.put<ResellerContact>(`/${contact.resellerID}/contacts/${contact.id}`, contact)).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }

  public getUserConfigs(resellerId: string, errs?: ResellerErrHandlers): Observable<ResellerUserConfig[]> {
    return from(this.httpClient.get<ResellerUserConfig[]>(`/${resellerId}/userconfigs`)).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }

  public getUserConfig(resellerId: string, configId: string, errs?: ResellerErrHandlers): Observable<ResellerUserConfig> {
    return from(this.httpClient.get<ResellerUserConfig>(`/${resellerId}/userconfigs/${configId}`)).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }

  public deleteUserConfig(resellerId: string, configId: string, errs?: ResellerErrHandlers): Observable<any> {
    return from(this.httpClient.delete<any>(`/${resellerId}/userconfigs/${configId}`)).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }

  public storeUserConfig(config: ResellerUserConfig, errs?: ResellerErrHandlers): Observable<ResellerUserConfig> {
    return from(this.httpClient.put<ResellerUserConfig>(`/${config.resellerID}/userconfigs/${config.id}`, config)).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }

  public activateTicket(rq: DisabledPricingRQ, errs?: PosErrHandlers): Observable<null> {
    return from(
      this.httpClient.delete(`/ticket/activate`, {
        params: rq,
      }),
    ).pipe(
      map(() => null),
      catchError(
        handleErrs((e, errs) => {
          if (errs.onPosNotFound && e.status === 404) {
            errs.onPosNotFound(e);
          }
        }, errs),
      ),
    );
  }

  public listDeactiveTickets(rq: DeactiveTicketsRQ, errs?: PosErrHandlers): Observable<ListDeactiveTickets[]> {
    return from(this.httpClient.get<ListDeactiveTickets[]>(`/disabledtickets/channels/${rq.channels}/suppliers/${rq.supplierId}`)).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }

  public disabledPricing(rq: DisabledPricingRQ, errs?: PosErrHandlers): Observable<null> {
    return from(this.httpClient.post(`/ticket/disable`, rq)).pipe(
      map(() => null),
      catchError(
        handleErrs((e, errs) => {
          if (errs.onPosNotFound && e.status === 404) {
            errs.onPosNotFound(e);
          }
        }, errs),
      ),
    );
  }

  public createAuditLog(req: AuditRecordRQ, errs?: CommonErrHandlers): Observable<any> {
    return from(this.httpClient.post<any>(`/${req.orgId}/audit`, JSON.stringify(req))).pipe(
      map(() => {}),
      catchError(handleErrs(() => {}, errs)),
    );
  }

  public checkFeatureFlags(errs?: CommonErrHandlers): Observable<FeatureFlags> {
    return from(
      this.httpClient.get<any>(`/featureFlags`, {
        baseURL: config.BackplaneApiUrl,
      }),
    ).pipe(
      map((rs) => rs.data),
      catchError(handleErrs(() => {}, errs)),
    );
  }
}

const resellerClient = new ResellerClient();
export default resellerClient;
