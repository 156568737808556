/* Availability Models */

export interface AcquireHoldRQ {
  hold?: HoldRQ;
  meta?: Meta;
}

export interface HoldRQ {
  items?: ItemRQ[];
}

export interface ItemRQ {
  at?: string;
  availabilityId?: string;
  productId?: string;
  priceId?: string;
  rateId?: string;
  supplierId?: string;
  quantity?: number;
  travelerType?: string;
}

export interface Meta {
  reqId?: string;
}

export interface AcquireHoldRS {
  hold?: Hold;
  meta?: Meta;
}

export interface Hold {
  expires?: string;
  ext?: Ext;
  id?: string;
  items?: HoldItem[];
  status?: string;
  timeline?: Timeline[];
}

export interface Ext {
  [key: string]: string;
}

/**
 * HoldItem - represents the data to hold for the booking
 */
export class HoldItem {
  id: string;
  supplierId: string;
  productId: string;
  rateId: string;
  priceId: string;
  availabilityId: string;
  at: string;
  travelerType: string;
  quantity: number;
  ext?: Ext;
}

export interface Rate {
  cancelable?: boolean;
  code?: string;
  ext?: Ext2;
  holdable?: boolean;
  holdablePeriod?: number;
  hours?: Hour[];
  id?: string;
  maxTravelers?: number;
  minTravelers?: number;
  name?: string;
  optionId?: string;
  prices?: Price[];
  pricingType?: string;
  productId?: string;
  refundable?: boolean;
  title?: string;
  type?: string;
  valid?: Valid2;
  version?: number;
}

export interface Ext2 {
  octo?: string;
}

export interface Hour {
  dates?: any[];
  daysOfWeek?: number[];
  times?: Time[];
  timezone?: string;
  valid?: Valid;
}

export interface Time {
  close?: string;
  open?: string;
}

export interface Valid {
  from?: string;
  until?: string;
}

export interface Price {
  id?: string;
  includedTaxes?: IncludedTax[];
  labels?: any[];
  name?: string;
  net?: Net;
  original?: Original;
  retail?: Retail;
  status?: string;
  travelerType?: TravelerType;
  unitId?: string;
}

export interface IncludedTax {
  currency?: string;
  id?: string;
  name?: string;
  net?: number;
  retail?: number;
}

export interface Net {
  amount?: number;
  currency?: string;
}

export interface Original {
  amount?: number;
  currency?: string;
}

export interface Retail {
  amount?: number;
  currency?: string;
}

export interface TravelerType {
  ageBand?: string;
  maxAge?: number;
  minAge?: number;
  modifier?: string;
  name?: string;
}

export interface Valid2 {
  from?: string;
  until?: string;
}

export interface Timeline {
  timestamp?: string;
  typeOf?: string;
}
