/* Booking Models */
import moment from "moment-timezone";
import { v4 as uuidv4 } from "uuid";
import { HoldItem } from "@/api/booking/AcquireHoldModels";
import { TicketLocation } from "@/api/booking/TicketLocationModel";

export interface BookingRQ {
  booking?: BookingData;
  meta?: Meta;
}

export interface BookingData {
  customer?: Customer;
  items?: ItemRQ[];
  barcode?: Barcode;
  ext?: Ext2;
  holdId?: string;
  id?: string;
  partnerId?: string;
  resellerBookingRef?: string;
}

export interface ItemRQ {
  priceId?: string;
  quantity?: number;
  rateId?: string;
  startTime?: string;
  supplierId?: string;
  traveler?: Traveler;
  availabilityId?: string;
  ext?: Ext;
  id?: string;
  productId?: string;
}

export interface BookingListRS {
  bookings?: Booking[];
  meta?: Meta;
}

export interface BookingDetailsRS {
  booking?: Booking;
  meta?: Meta;
}

export interface Booking {
  barcode?: Barcode;
  cancelable?: boolean;
  customer?: Customer;
  ext?: Ext;
  holdId?: string;
  id?: string;
  items?: Item[];
  partnerId?: string;
  resellerBookingRef?: string;
  status?: string;
  tickets?: Ticket[];
  timeline?: Timeline[];
  version?: number;
}

export interface Barcode {
  format?: string;
  value?: string;
}

export interface Customer {
  email?: string;
  firstName?: string;
  lastName?: string;
  phone?: string;
  clientRef?: string;
}

export interface Ext {
  string?: string;
  "supplier.reference"?: string;
}

export interface Item {
  availabilityId?: string;
  ext?: Ext2;
  id?: string;
  priceId?: string;
  productId?: string;
  quantity?: number;
  rate?: Rate;
  rateId?: string;
  startTime?: string;
  status?: string;
  supplierId?: string;
  traveler?: Traveler;
}

export interface Ext2 {
  string?: string;
  "x-resellerPortal.AgentName"?: string;
  "x-resellerPortal.AgentEmail"?: string;
}

export interface Rate {
  cancelable?: boolean;
  code?: string;
  cutoff?: number;
  ext?: Ext3;
  holdable?: boolean;
  holdablePeriod?: number;
  hours?: Hour[];
  id?: string;
  maxTravelers?: number;
  minTravelers?: number;
  name?: string;
  optionId?: string;
  partnerId?: string;
  prices?: Price[];
  productId?: string;
  refundable?: boolean;
  title?: string;
  type?: string;
  valid?: Valid2;
  version?: number;
}

export interface Ext3 {
  string?: string;
}

export interface Hour {
  dates?: string[];
  daysOfWeek?: number[];
  times?: Time[];
  timezone?: string;
  valid?: Valid;
}

export interface Time {
  close?: string;
  open?: string;
}

export interface Valid {
  from?: string;
  until?: string;
}

export interface Price {
  id?: string;
  includedTaxes?: IncludedTax[];
  labels?: string[];
  name?: string;
  net?: Net;
  original?: Original;
  retail?: Retail;
  status?: string;
  travelerType?: TravelerType;
}

export interface IncludedTax {
  currency?: string;
  id?: string;
  name?: string;
  net?: number;
  retail?: number;
}

export interface Net {
  amount?: number;
  currency?: string;
}

export interface Original {
  amount?: number;
  currency?: string;
}

export interface Retail {
  amount?: number;
  currency?: string;
}

export interface TravelerType {
  ageBand?: string;
  maxAge?: number;
  minAge?: number;
  modifier?: string;
  name?: string;
}

export interface Valid2 {
  from?: string;
  until?: string;
}

export interface Traveler {
  age?: number;
  country?: string;
  email?: string;
  firstName?: string;
  gender?: string;
  isLead?: boolean;
  lang?: string;
  lastName?: string;
  phone?: string;
  type?: string;
}

export interface Ticket {
  barcode?: Barcode2;
  bookingItemIds?: string[];
  extensions?: Extensions;
  guests?: Guest[];
  leadTraveler?: LeadTraveler;
  status?: string;
}

export interface Barcode2 {
  format?: string;
  value?: string;
}

export interface Extensions {
  string?: string;
}

export interface Guest {
  count?: number;
  typeCode?: string;
  typeModifier?: string;
  typeName?: string;
}

export interface LeadTraveler {
  age?: number;
  country?: string;
  email?: string;
  firstName?: string;
  gender?: string;
  isLead?: boolean;
  lang?: string;
  lastName?: string;
  phone?: string;
  type?: string;
}

export interface Timeline {
  timestamp?: string;
  typeOf?: string;
}

export interface Meta {
  reqId?: string;
}

export interface BookingItem {
  id?: string;
  resellerBookingRef?: string;
  supplierBookingRef?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  confirmation?: string;
  bookedDate?: string;
  status?: string;
}

export interface BookingInfoRS {
  supplierName?: string;
  supplierLocation?: string;
  startDate?: string;
  status?: string;
  noOfDays?: string;
  productName?: string;
  rateName?: string;
  noOfTickets?: number;
  validStartDate?: string;
  validEndDate?: string;
  validityDays?: number;
  confirmationBarcode?: string;
  confirmationBarcodeFormat?: string;
  pricingInfoHeaders?: PricingInfoHeader;
  pricingInfoData?: PricingInfoData[];
  pricingTotal?: number;
  currency?: string;
  customerInfo?: CustomerInfo;
  policyInfo?: PolicyInfo;
  timeline?: any;
  cancelable?: boolean;
  id?: string;
  parks?: any;
  productDuration?: number;
  ticketStartDate?: string;
  refundable?: boolean;
  ParkReservationStatus?: string;
  parkReservationRequired?: string;
  seats?: Array<TicketLocation>;
  dateBasedTicket?: boolean;
  ext?: any;
}

export interface PricingInfoHeader {
  type?: string;
  pricePerTicket?: string;
  taxesFees?: string;
  total?: string;
}

export interface PricingInfoData {
  priceID?: string;
  name?: string;
  type?: string;
  ageBand?: string | number;
  unitId?: string;
  pricePerTicket: number | null;
  taxesFees: number | null;
  noOfTravellers: number;
  total: number | null;
  currency?: string;
  priceName?: string;
}

export interface CustomerInfo {
  name?: string;
  email?: string;
  phone?: string;
}

export interface PolicyInfo {
  productDesc?: string;
  policy?: string;
}

/**
 * AvailabilityTimeSlot - mapping of a single availability with times and capacity
 */
export class AvailabilityTimeSlot {
  id: string;
  capacity: number;
  start: string;
  end: string;
  localTimezone: string;

  getLocalStartTime(format?: string): string {
    return moment.tz(this.start, this.localTimezone).format(format);
  }
  getLocalEndTime(format?: string): string {
    return moment.tz(this.end, this.localTimezone).format(format);
  }
}

/**
 * CartItem - represents a unique item to be purchased
 * this is later mapped into the hold and booking items
 */
export class CartItem {
  supplierID: string;
  productID: string;
  rateID: string;
  priceID: string;
  travelerType: string;
  startTime: string;
  availabilityID: string;
  quantity: number;
  ext?: any;

  mapToHoldItem(): HoldItem {
    const item = new HoldItem();
    item.id = uuidv4();
    item.supplierId = this.supplierID;
    item.productId = this.productID;
    item.rateId = this.rateID;
    item.priceId = this.priceID;
    item.availabilityId = this.availabilityID;
    item.at = this.startTime;
    item.travelerType = this.travelerType;
    item.quantity = this.quantity;
    return item;
  }

  mapToBookingItem(): Item {
    const item = {
      id: uuidv4(),
      supplierId: this.supplierID,
      productId: this.productID,
      rateId: this.rateID,
      priceId: this.priceID,
      availabilityId: this.availabilityID,
      startTime: this.startTime,
      traveler: {
        type: this.travelerType,
      },
      quantity: this.quantity,
    };
    return item;
  }
}

/* Errors */
export enum ErrorCodeEnum {
  BADREQUEST = "BAD_REQUEST",
  CONFLICT = "CONFLICT",
  ENTITYTOOLARGE = "ENTITY_TOO_LARGE",
  FAILEDDEPENDENCY = "FAILED_DEPENDENCY",
  FORBIDDEN = "FORBIDDEN",
  GONE = "GONE",
  INTERNALSERVER = "INTERNAL_SERVER",
  NOTFOUND = "NOT_FOUND",
  UNAUTHORIZED = "UNAUTHORIZED",
}
